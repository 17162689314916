<template>
  <div class="transaction-card">
    <div class="transaction-card__info">
      <div class="transaction-card__date-time">
        <span class="transaction-card__font transaction-card__font--base">
          {{ dateTime.date }}
        </span>

        <span class="transaction-card__font transaction-card__font--secondary">
          {{ dateTime.time }}
        </span>
      </div>

      <div class="transaction-card__card">
        <span class="transaction-card__font transaction-card__font--base">
          <img :src="cardImage" class="transaction-card__image" />
          {{ curdNumber }}
        </span>

        <span class="transaction-card__font transaction-card__font--secondary">
          &nbsp;{{ isCardNumber ? $t("general.my-cards.card") : ''  }}
        </span>
      </div>

      <div class="transaction-card__amount">
        <span class="transaction-card__font transaction-card__font--base">
          {{ isPlus }}{{ amount }}
        </span>

        <span class="transaction-card__font transaction-card__font--secondary">
          {{  $t("general.my-cards.amount") }}
        </span>
      </div>
    </div>

    <UiBadge class="transaction-card__badge" size="medium" v-if="description">
      <span class="transaction-card__font transaction-card__font--badge">
        {{ description.message }}
        <CLink v-if="description.user" :to="`/${description.user}`">
          {{ description.name }}
        </CLink>
      </span>
    </UiBadge>
  </div>
</template>
<script>
import UiBadge from "@/features/ui/common/UiBadge.vue";
import { currencyFormat, formatDateTime } from "@/tools/helpers";
import { mapGetters } from "vuex";
import CLink from "@/features/ui/CLink.vue";
import { paymentMethods } from "@/config/paymentMethods";

export default {
  name: "TransactionCard",
  components: { CLink, UiBadge },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings",
    }),
    cardImage() {
      switch (this.brand) {
        case paymentMethods.Visa:
        case paymentMethods.VisaDebit:
          return "/img/payments/visa-small.webp";
        case paymentMethods.MasterCard:
        case paymentMethods.MasterCardDebit:
          return "/img/payments/mastercard-small.webp";
        case paymentMethods.Discover:
          return "/img/payments/discover-small.webp";
        case paymentMethods.AMEX:
          return "/img/payments/mastercard-small.webp";
        default:
          return "/img/payments/default-small.webp";
      }
    },
    dateTime() {
      return this.formatDateTime(this.card?.created_at);
    },

    isPlus() {
      const cardType = this.card?.type;
      const answerCode = this.settings?.payment_types?.[cardType];
      const answers = {
        TYPE_SUBSCRIPTION_NEW: "Subscription",
        TYPE_SUBSCRIPTION_RENEW: "",
        TYPE_POST: "",
        TYPE_MESSAGE: "",
        TYPE_TIP: `Tip to ${this.card?.to?.name || ""}`,
        TYPE_WALLET_DEPOSIT: "Added funds from card",
      };

      return answers?.[answerCode] === answers.TYPE_WALLET_DEPOSIT ? "+" : "-";
    },

    description() {
      const cardType = this.card?.type;
      const answerCode = this.settings?.payment_types?.[cardType];
      const answers = {
        TYPE_SUBSCRIPTION_NEW: {
          message: `Subscription to `,
          user: this.card?.items?.sub?.username || "",
          name: this.card?.items?.sub?.name || "",
        },
        TYPE_SUBSCRIPTION_RENEW: {
          message: "Renew subscription ",
          user: this.card?.items?.sub?.username || "",
          name: this.card?.items?.sub?.name || "",
        },
        TYPE_POST: {
          message: "Unlocked ",
          user: `${this.card?.items?.post?.id}/${this.card?.items?.post?.user?.name}`,
          name: "Post",
        },
        TYPE_MESSAGE: {
          message: "Tip message",
          user: this.card?.items?.sub?.username || "",
          name: this.card?.items?.sub?.name || "",
        },
        TYPE_TIP: {
          message: `Tip to `,
          user: this.card?.to?.username || "",
          name: this.card?.to?.name || "",
        },
        TYPE_WALLET_DEPOSIT: { message: "Added funds from card" },
      };

      return answers[answerCode] || "";
    },

    amount() {
      return this.currencyFormat(this.card?.amount || 0);
    },
    brand() {
      return this.card?.info?.payment_method?.brand || "";
    },
    curdNumber() {
      const text = this.card?.title?.replaceAll("*", "");
      return text ? `...${text}` : "WALLET";
    },
    isCardNumber() {
      return Boolean(this.card?.title?.replaceAll("*", ""))
    },
  },
  methods: {
    currencyFormat,
    formatDateTime,
  },
};
</script>

<style lang="scss" scoped>
.transaction-card {
  border: 1.5px solid $app-gray-25;
  border-radius: 10px;
  width: 100%;
  height: 100px;
  padding: 16px 15px 18px;
  margin-top: 16px;

  &__image {
    display: inline-block;
    width: 29px;
    height: 17px;
  }

  &__font {
    &--base {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      color: $black;
    }

    &--secondary {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 10px;
      text-align: right;
      color: $app-gray-18;
      text-transform: uppercase;
    }

    &--badge {
      font-size: 12px;
      color: $app-gray-19;
    }

    &--name {
      color: $app-blue;
    }
  }

  &__badge {
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__date-time {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__amount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__badge {
    margin-top: em(6);
    height: em(26);
  }
}
</style>
